import Collection from './icons/Collection'
import Home from './icons/Home'
import Profile from './icons/Profile'
import SearchIcon from './icons/SearchIcon'
import { Container, NavItem, Title } from './styles'
import { BukaApp } from '@/components/BukaApp'
import {
    TRIGGER_COLLECTION_PAGE_VIEWED,
    TRIGGER_PROFILE_PAGE_VIEWED,
} from '@/helpers/events'
// import { useRouter } from 'next/router'
import { updateNav } from '@/store/actions/home'
import {
    setShowDownloadPrompt,
    setShowLoginPrompt,
} from '@/store/actions/player'
import {
    updateFocus,
    updateSearchTerm,
    getSearchResult,
} from '@/store/actions/search'
import { _activeNav } from '@/store/selectors/home'
import { NAVTYPES } from '@/store/types/home'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const BottomNavigation = () => {
    const { data: session, status } = useSession()
    const activeNav = useSelector(_activeNav)
    // const router = useRouter()

    const dispatch = useDispatch()

    const isNavActive = (currentNav) =>
        activeNav === currentNav ? true : false

    const switchNav = (currentNav) => {
        switch (currentNav) {
            case NAVTYPES.HOME: {
                dispatch(updateNav(currentNav))
                // router.push('/')
                break
            }
            case NAVTYPES.SEARCH: {
                dispatch(updateNav(currentNav))
                dispatch(updateFocus(false))
                dispatch(updateSearchTerm(''))
                dispatch(getSearchResult({ data: [] }))
                // router.push('/search')
                break
            }
            case NAVTYPES.COLLECTION: {
                TRIGGER_COLLECTION_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            case NAVTYPES.PROFILE: {
                TRIGGER_PROFILE_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            default:
                dispatch(setShowDownloadPrompt(true))
        }
    }

    return (
        <Container>
            <Link href={'/'} prefetch={false}>
                <a>
                    <NavItem onClick={() => switchNav(NAVTYPES.HOME)}>
                        <Home active={isNavActive(NAVTYPES.HOME)} />
                        <Title active={isNavActive(NAVTYPES.HOME)}>
                            {NAVTYPES.HOME}
                        </Title>
                    </NavItem>
                </a>
            </Link>
            {/* <Link prefetch={false}>
                <a className={isNavActive(NAVTYPES.BUKA_APP) ? `active` : ''}>
                    <NavItem onClick={() => switchNav(NAVTYPES.BUKA_APP)}>
                        <BukaAppIcon active={isNavActive(NAVTYPES.BUKA_APP)} />
                        <Title active={isNavActive(NAVTYPES.BUKA_APP)}>
                            {NAVTYPES.BUKA_APP}
                        </Title>
                    </NavItem>
                </a>
            </Link> */}
            <Link href={'/search'} prefetch={false}>
                <a>
                    <NavItem onClick={() => switchNav(NAVTYPES.SEARCH)}>
                        <SearchIcon active={isNavActive(NAVTYPES.SEARCH)} />
                        <Title active={isNavActive(NAVTYPES.SEARCH)}>
                            {NAVTYPES.SEARCH}
                        </Title>
                    </NavItem>
                </a>
            </Link>

            <BukaApp />

            <Link
                href={
                    status === 'authenticated' && session?.isLoggedin
                        ? '/collection'
                        : '#/'
                }
                prefetch={false}
            >
                <a>
                    <NavItem onClick={() => switchNav(NAVTYPES.COLLECTION)}>
                        <Collection active={isNavActive(NAVTYPES.COLLECTION)} />
                        <Title active={isNavActive(NAVTYPES.COLLECTION)}>
                            {NAVTYPES.COLLECTION}
                        </Title>
                    </NavItem>
                </a>
            </Link>
            <Link
                href={
                    status === 'authenticated' && session?.isLoggedin
                        ? '/my-profile'
                        : '#/'
                }
                prefetch={false}
            >
                <a>
                    <NavItem onClick={() => switchNav(NAVTYPES.PROFILE)}>
                        <Profile active={isNavActive(NAVTYPES.PROFILE)} />
                        <Title active={isNavActive(NAVTYPES.PROFILE)}>
                            {NAVTYPES.PROFILE}
                        </Title>
                    </NavItem>
                </a>
            </Link>
        </Container>
    )
}

export default BottomNavigation
